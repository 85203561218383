import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import EditOrderModal from "../../../Modals/EditOrder/index";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { setCurrent, setCurrentId, setEdit, setEditOrder, setPaymentCard, setPaymentMethod, setPickUpDateDelivery, setPickUpIssueFix, setPickUpSlotDelivery, setSelectedAddress } from "../../../../../store/Actions/authActions";
import { cartApi, cartEdit, fetchCartItem, selectAddress, setTimePickup } from "../../../../../store/Actions/cartActions";

const MobileProgress = (props) => {
  const editStyle = {
    width: '100%',
    maxWidth: '130px',
    background: '#3d4586',
    color: '#ffffff',
  }

  const { t } = useTranslation();
  const [key, setKey] = useState(props.id);

  const [orders, setOrders] = useState([]);

  console.log(props?.current.pickup_date, 'pickup date')
  console.log(props?.current.pickup_slot, 'pickup date')

  const orderArray = () => {
    if (props?.current) {
      let array = props?.current?.products?.map((item, index) => {
        const result = props?.items?.find((product) => {
          if (product.id === item.product_id) {
            return product;
          }
        });
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
        };
      });
      setOrders(array);
    }
  };

  useEffect(() => {
    orderArray();
    setKey(props.id);
  }, [props]);

  const handleEditOrder = async () => {
    let array = props?.current?.products?.map((item) => {
      const result = props?.items?.find(
        (product) => product.id === item.product_id && product?.is_active
      );
      if (result?.is_active) {
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
          table_id: item?.id,
          order_id: item?.order_id,
        };
      }
    });
  
    let final = array.filter((item) => item !== undefined);
  
    let type = props?.current?.collection_point_id ? "office" : "home";
    if (type === "home") {
      props?.dispatch(setSelectedAddress(`${props?.current?.delivery_address_id}`, type));
      props?.dispatch(selectAddress(type));
    } else {
      props?.dispatch(setSelectedAddress(`${props?.current?.collection_point_id}`, "office"));
      props?.dispatch(selectAddress(type));
    }
  
    props?.dispatch(setCurrentId(props?.current?.id));
    props?.dispatch(setCurrent(props?.current?.products));
    props?.dispatch(setEditOrder(true));
    props?.dispatch(setEdit(false));
    props?.dispatch(
      setPaymentCard(
        props?.current?.payment_method_id ? props?.current?.payment_method_id : null
      )
    );
    props?.dispatch(
      setPaymentMethod(
        props?.current?.payment_method_id ? "Debit Card" : "Wallet"
      )
    );
  
    await synchronizeCart();
    props?.history?.push("/home");
  };

  const handleEditDelivery = async () => {
    let array = props?.current?.products?.map((item) => {
      const result = props?.items?.find(
        (product) => product.id === item.product_id && product?.is_active
      );
      if (result?.is_active) {
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
          table_id: item?.id,
          order_id: item?.order_id,
        };
      }
    });

    let final = array.filter((item) => item !== undefined);

    let type = props?.current?.collection_point_id ? "office" : "home";
    if (type === "home") {
      props?.dispatch(setSelectedAddress(`${props?.current?.delivery_address_id}`, type));
      props?.dispatch(selectAddress(type));
    } else {
      props?.dispatch(setSelectedAddress(`${props?.current?.collection_point_id}`, "office"));
      props?.dispatch(selectAddress(type));
    }

    props?.dispatch(setCurrentId(props?.current?.id));
    props?.dispatch(setCurrent(props?.current?.products));
    props?.dispatch(setEditOrder(false));
    props?.dispatch(setEdit(true));
    props?.dispatch(setPickUpIssueFix(false))
    props?.dispatch(setPickUpDateDelivery(props?.current.pickup_date));
    props?.dispatch(setPickUpSlotDelivery(props?.current.pickup_slot));
    props?.dispatch(
      setPaymentCard(
        props?.current?.payment_method_id ? props?.current?.payment_method_id : null
      )
    );
    props?.dispatch(
      setPaymentMethod(
        props?.current?.payment_method_id ? "Debit Card" : "Wallet"
      )
    );

    await synchronizeCart();
    props?.history?.push("/checkout");
  };

  const synchronizeCart = async () => {
    try {
      await props.dispatch(fetchCartItem());

      await Promise.all(
        props?.cartItems?.map((p) =>
          props?.dispatch(cartApi(p, "destroyAllProducts"))
        )
      );

      await props?.dispatch(cartEdit(props.current, props?.history));
      await props.dispatch(fetchCartItem());
    } catch (error) {
      console.error("Error synchronizing cart:", error);
    }
  };

  return (
    <div className="w_95per jusify-content-center ms-sm-auto mx-auto d-flex flex-sm-row flex-column ps-0 mt-4">
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0 ps-sm-5 ps-0">
            <div>
              <div className={props?.step >= 1 ? "progress_circle progress_circle_active" : "progress_circle progress_circle_active"} />
            </div>
            <div className={props?.step >= 1 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <h6 className="theme_color">{t("Order confirmed")}</h6>
          {props?.step === 1 && !props.current?.pick_up_issue && (
            <>
              <button
                onClick={() => {
                  handleEditOrder();
                }}
                className="d-flex justify-content-center small align-items-center border-0 rounded-pill px-4"
                style={editStyle}
              >
                {t("Edit order")}
              </button>
              {/* <EditOrderModal
                history={props?.history}
                id={key}
                current={props.current}
              /> */}
            </>
          )}
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            <div>
              <div className={props.step >= 2 && props.current?.pick_up_issue ? "progress_circle progress_circle_active_issue" : props.step >= 2 && !props.current?.pick_up_issue ? "progress_circle_active progress_circle" : "progress_circle"} />
            </div>
            <div className={props.step >= 2 && !props.current?.pick_up_issue ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>
        <div className="d-flex justify-content-lg-center justify-content-md-start justify-content-sm-center ms-md-2">
          <h6 className="theme_color">{t("Picked-up")} </h6>
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            <div>
              <div className={props.step >= 3 && props.current?.payment_status == 'failed' ? "progress_circle progress_circle_active_issue" : props.step >= 3 && props.current?.payment_status !== 'failed' ? "progress_circle_active progress_circle" : "progress_circle"} />
            </div>
            <div className={props.step >= 3 && props.current?.payment_status !== 'failed' ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>
        <div className="d-flex justify-content-center mr-md-2">
          <h6 className="theme_color">{t("Processed")} </h6>
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            <div>
              <div className={props.step >= 4 ? "progress_circle progress_circle_active" : "progress_circle"} />
            </div>
            <div className={props.step >= 4 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-md-center ms-md-3">
          <h6 className="theme_color">{t("Ready")} </h6>
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            <div>
              <div className={props.step === 5 && props.current?.delivery_issue  ? "progress_circle progress_circle_active_issue" : "progress_circle"} />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-md-center ms-md-5">
          <h6 className="theme_color">{t("Retrieved")}</h6>
          {props?.step > 1 && props.step !== 'confirmed' && !props.current?.pick_up_issue &&  (props.current?.payment_status === 'paid' || props.current?.payment_status === 'to_invoice') && (
            <>
              <button
                onClick={() => {
                  handleEditDelivery();
                }}
                className="d-flex justify-content-center small align-items-center border-0 rounded-pill"
                style={editStyle}
              >
                {t("Edit Delivery")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state?.cart?.mockItems,
    homeAddress: state?.auth?.homeAddress,
    officeAddress: state?.auth?.officeAddress,
    pId: state?.auth?.paymentId,
    cartItems: state?.cart?.cartItems,
  };
};

export default connect(mapStateToProps)(MobileProgress);
