import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Axios from "axios";
import Config from "../../../Config";
import moment from "moment";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { setEdit, setState, setCurrent, setCurrentId, } from "../../../../store/Actions/authActions";
import { setClearCartState } from "../../../../store/Actions/cartActions";

class EditDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      msg: [],
      phone: ''
    };
  }

  componentDidMount() { }

  formatDateWithDay(apiDate) {
    const [day, monthName, year] = apiDate.split(" ");
    const monthNames = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
      Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
    const month = monthNames[monthName];
    const dateObj = new Date(`${year}-${month}-${day.padStart(2, '0')}`);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[dateObj.getDay()];

    return `${dayOfWeek} - ${year}-${month}-${day.padStart(2, '0')}`;
  }

  changeDateFormat(dateWithDay) {
    const datePart = dateWithDay.split(" - ")[1];
    const [year, month, day] = datePart.split("-");
    return `${day}-${month}-${year}`;
  }

  handleEditDelivery = async () => {
    const { pickUpDateDelivery } = this.props;
    if (!pickUpDateDelivery) {
      console.error('No date provided');
      return;
    }
    const formattedDateWithDay = this.formatDateWithDay(pickUpDateDelivery);
    const formattedDate = this.changeDateFormat(formattedDateWithDay);
    this.setState({
      loading: true,
    });
    const now = new Date();
    let products = this.props?.cartItems.map((item) => {
      if (this.props?.currentOrder?.filter((p) => item?.id === p?.product_id)?.length > 0) {
        let temp = null
        this.props?.currentOrder?.forEach((p) => {
          if (item.id === p?.product_id) {
            temp = {
              ...p,
              quantity: item?.quantity
            }
          }
        })
        return temp;
      }
      else {
        if (this.props?.currentOrder?.filter((p) => p?.product_id === item?.id)?.length === 0) {
          return {
            product_id: item?.id,
            quantity: item?.quantity,
            order_id: this.props?.currentId,
          };
        }
      }
    });
    let data;
    this.props?.currentOrder?.forEach((p) => {
      if (this.props?.cartItems?.filter((i) => i.id === p?.product_id)?.length === 0) {
        products?.push({
          ...p,
          _destroy: 1
        })
      }
    })

    if (this.props?.paymentMethod === "Wallet") {
      data = {
        order: {
          pickup_date: `${formattedDate}`,
          delivery_date: `${this.props?.time
            ? `${this.props?.time?.date?.day} - ${this.props?.time?.date?.date}`
            : `${moment(now).format("DD/MM/YYYY")}`
            }`,
          pickup_slot: this.props?.pickUpSlotDelivery,
          delivery_slot: this.props?.deliverySlot,
          orderable_id:
            this.props?.Saddress && this.props?.Saddress?.[0]?.id
              ? this.props?.Saddress?.[0]?.id
              : null,
          payment_type: "Wallet",
          orderable_type:
            this.props?.abc !== null && this.props?.abc
              ? "DeliveryAddress"
              : "CollectionPoint",

          promo_code_id:
            this.props?.pCode && this.props?.pCode?.id
              ? this.props?.pCode?.id
              : null,
          product_orders_attributes: products,
          description: this.props?.desc,
          delivery_update: true,
        },
      };
      try {
        let response = await Axios({
          url: `${Config.apiURL}/customer/orders/${this.props?.currentId}`,
          method: "PUT",
          data: data,
          headers: {
            uid: this.props?.authUser?.user_uid,
            client: this.props?.authUser?.user_client,
            "access-token": this.props?.authUser?.user_access_token,
          },
        });

        localStorage.removeItem("p_id");
        localStorage.removeItem("tM");
        this.props?.dispatch(setEdit(false));
        this.props?.dispatch(setState());
        let cartResponse = await Axios({
          url: `${Config.apiURL}/customer/cart/`,
          method: "GET",
          headers: {
            uid: this.props?.authUser?.user_uid,
            client: this.props?.authUser?.user_client,
            "access-token": this.props?.authUser?.user_access_token,
          },
        });

        const cart = cartResponse.data?.cart?.cart_products;
        const empty = cart?.map((item) => {
          return {
            id: item.id,
            _destroy: 1
          }
        })
        await Axios({
          url: `${Config.apiURL}/customer/cart/`,
          method: "PUT",
          headers: {
            uid: this.props?.authUser?.user_uid,
            client: this.props?.authUser?.user_client,
            "access-token": this.props?.authUser?.user_access_token,
          },
          data: {
            cart: {
              cart_products_attributes: empty
            }
          }
        });
        this.props.dispatch(setEdit(false))
        this.props?.dispatch(setCurrentId(null))
        this.props.dispatch(setCurrent(null))
        this.props?.dispatch(setClearCartState());
        this.setState({
          loading: false,
        });
        this.props?.history?.push("/purchase-success");
      } catch (err) {
        let str = "";
        if (err?.response?.data) {
          this.setState({
            loading: false,
            error: true,
          });
          Object.keys(err?.response?.data?.message).forEach((key) => {
            let value = err?.response?.data?.message[key];
            if (key === "orderable") {
              key = "delivery address";
            }
            if (key === "order") {
              key = "";
            }
            str = key?.replace("_", " ") + " " + value;
            this.handleValidate(str?.toLowerCase());
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
          this.handleValidate("Something went wrong, try again later");
        }
      }
    } else if (this.props?.paymentMethod === "Debit Card") {
      data = {
        order: {
          pickup_date: `${formattedDate}`,
          delivery_date: `${this.props?.time
            ? `${this.props?.time?.date?.day} - ${this.props?.time?.date?.date}`
            : `${moment(now).format("DD/MM/YYYY")}`
            }`,
          pickup_slot: this.props?.pickUpSlotDelivery,
          delivery_slot: this.props?.deliverySlot,
          orderable_id:
            this.props?.Saddress && this.props?.Saddress?.[0]?.id
              ? this.props?.Saddress?.[0]?.id
              : null,
          payment_type: "Card",
          payment_method_id: this.props?.paymentCard,
          orderable_type:
            this.props?.abc !== null && this.props?.abc
              ? "DeliveryAddress"
              : "CollectionPoint",

          promo_code_id:
            this.props?.pCode && this.props?.pCode?.id
              ? this.props?.pCode?.id
              : null,
          product_orders_attributes: products,
          description: this.props?.desc,
          delivery_update: true,
        },
      };
      try {
        let response = await Axios({
          url: `${Config.apiURL}/customer/orders/${this.props?.currentId}`,
          method: "PUT",
          data: data,
          headers: {
            uid: this.props?.authUser?.user_uid,
            client: this.props?.authUser?.user_client,
            "access-token": this.props?.authUser?.user_access_token,
            // ),
          },
        });

        localStorage.removeItem("p_id");
        localStorage.removeItem("tM");
        this.props?.dispatch(setEdit(false));
        this.props?.dispatch(setState());
        this.props?.dispatch(setClearCartState());
        this.setState({
          loading: false,
        });
        this.props?.history?.push("/purchase-success");
      } catch (err) {
        let str = "";
        if (err?.response?.data) {
          this.setState({
            loading: false,
            error: true,
          });
          Object.keys(err?.response?.data?.message).forEach((key) => {
            let value = err?.response?.data?.message[key];
            if (key === "orderable") {
              key = "delivery address";
            }
            if (key === "order") {
              key = "";
            }
            str = key?.replace("_", " ") + " " + value;
            this.handleValidate(str?.toLowerCase());
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
          this.handleValidate("Something went wrong, try again later");
        }
      }
    } else {
      data = {
        order: {
          pickup_date: `${formattedDate}`,
          delivery_date: `${this.props?.time
            ? `${this.props?.time?.date?.day} - ${this.props?.time?.date?.date}`
            : `${moment(now).format("DD/MM/YYYY")}`
            }`,
          pickup_slot: this.props?.pickUpSlotDelivery,
          delivery_slot: this.props?.deliverySlot,
          orderable_id:
            this.props?.Saddress && this.props?.Saddress?.[0]?.id
              ? this.props?.Saddress?.[0]?.id
              : null,
          orderable_type:
            this.props?.abc !== null && this.props?.abc
              ? "DeliveryAddress"
              : "CollectionPoint",

          promo_code_id:
            this.props?.pCode && this.props?.pCode?.id
              ? this.props?.pCode?.id
              : null,
          product_orders_attributes: products,
          description: this.props?.desc,
          delivery_update: true,
        },
      };
      try {
        let response = await Axios({
          url: `${Config.apiURL}/customer/orders/${this.props?.currentId}`,
          method: "PUT",
          data: data,
          headers: {
            uid: this.props?.authUser?.user_uid,
            client: this.props?.authUser?.user_client,
            "access-token": this.props?.authUser?.user_access_token,
            // ),
          },
        });

        localStorage.removeItem("p_id");
        localStorage.removeItem("tM");
        this.props?.dispatch(setEdit(false));
        this.props?.dispatch(setState());
        this.props?.dispatch(setClearCartState());
        this.setState({
          loading: false,
        });
        this.props?.history?.push("/purchase-success");
      } catch (err) {
        let str = "";
        if (err?.response?.data) {
          this.setState({
            loading: false,
            error: true,
          });
          Object.keys(err?.response?.data?.message).forEach((key) => {
            let value = err?.response?.data?.message[key];
            if (key === "orderable") {
              key = "delivery address";
            }
            if (key === "order") {
              key = "";
            }
            str = key?.replace("_", " ") + " " + value;
            this.handleValidate(str?.toLowerCase());
          });
        } else {
          this.setState({
            loading: false,
            error: true,
          });
          this.handleValidate("Something went wrong, try again later");
        }
      }
    }

    // pass the token to your backend API
  };

  handleValidate = (msg) => {
    this.props.errr(msg)
    ToastsStore.error(this.props?.t(msg), 5000, "text-left");
  };

  toast = () => {
    return <ToastsContainer store={ToastsStore} className="text-left" />;
  };

  item = () => {
    return this?.props?.cartItems?.map((item, index) => {
      return (
        <>
          <div className="row mb-2 mt-3">
            <div className="col-4">
              <h6 className="fw-bold mb-1">{this.props.t(item.name)}</h6>
            </div>
            <div className="col-4">
              <h6 className="mb-1 text-center">x{item?.quantity}</h6>
            </div>
            <div className="col-4">
              <p className="mb-0 text-end">
                {parseFloat(item?.price)?.toFixed(2)?.replace(".", ",")} €{" "}
              </p>
            </div>
          </div>
        </>
      );
    });
  };

  render() {
    const { pickUpDateDelivery, pickUpSlotDelivery } = this.props;
    if (!pickUpDateDelivery) {
      console.error('No date provided');
      return null;
    }
    const formattedDateWithDay = this.formatDateWithDay(pickUpDateDelivery);
    const formattedDate = this.changeDateFormat(formattedDateWithDay);
    return (
      <div>
        <div
          className="modal fade"
          id="editdelivery"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border_color position-relative">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer position-absolute top-0 start-100 translate-middle"
                data-bs-dismiss="modal"
                aria-hidden="true"
                style={{
                  border: "6px solid #3D4586",
                  borderRadius: 50,
                  marginRight: -20,
                  marginBottom: -25,
                  zIndex: 1000,
                  backgroundColor: "#3D4586",
                }}
              />
              <div className="modal-body theme_color rounded_25px px-2">
                <div className="mb-5">
                  <h5 className="theme_color fw-bold text-center font_style">
                    {this.props.t("confirm your order")}
                  </h5>
                </div>
                {this.props?.Saddress && (
                  <div className="d-flex justify-content-between mb-2">
                    <h6 className="m-0 fw-bold">{this.props.t("Address")}</h6>
                    <p className="m-0 w-75 text-right">
                      {this.props?.Saddress?.[0]?.address}
                    </p>
                  </div>
                )}
                {this.props?.Saddress && this.props?.Saddress?.[0] && this.props?.Saddress[0]?.type && this.props?.Saddress[0]?.type === 'CollectionPoint' && this.props?.Saddress[0]?.companies[0]?.company_name &&
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h6 className="m-0 fw-bold">{this.props.t("Company")}</h6>
                    <p className="m-0">
                      {this.props?.Saddress[0]?.companies[0]?.company_name}
                    </p>
                  </div>
                }
                <div
                  className="theme_color mt-3 w-75 mx-auto"
                  style={{ height: "1px", background: "#3d4586" }}
                ></div>
                <div className="row mb-2 mt-3">
                  <div className="col-4">
                    <p className="fw-bold mb-1">{this.props.t("pick-up")}</p>
                  </div>
                  <div className="col-4">
                    <p className="mb-1 text-center">
                      {formattedDate}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="mb-0 text-end">{pickUpSlotDelivery}</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="fw-bold mb-1">{this.props.t("Delivery")}</p>
                  </div>
                  <div className="col-4">
                    <p className="mb-1 text-center">
                      {this.props?.time?.date &&
                        this.props?.time?.date?.date?.split("-")?.[2] +
                        "-" +
                        this.props?.time?.date?.date?.split("-")?.[1] +
                        "-" +
                        this.props?.time?.date?.date?.split("-")?.[0]}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="mb-0 text-end">{this?.props?.deliverySlot}</p>
                  </div>
                </div>
                <div
                  className="theme_color mt-3 w-75 mx-auto"
                  style={{ height: "1px", background: "#3d4586" }}
                ></div>
                {this.item()}
                <div
                  className="theme_color mt-3 w-75 mx-auto"
                  style={{ height: "1px", background: "#3d4586" }}
                ></div>

                {this.props?.pCode ? (
                  <div className="row mb-2 mt-3">
                    <div className="col-6">
                      <h6 className="fw-bold mb-1">{this.props.t("discount")}</h6>
                    </div>
                    <div className="col-6">
                      <p className="mb-0 text-end">
                        {this.props?.pCode?.fixed_amount ?
                          this.props?.pCode?.discount + "€"
                          :
                          this.props?.pCode?.discount + "%"
                        }
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row mb-2 mt-3">
                    <div className="col-6">
                      <h6 className="fw-bold mb-1">{this.props.t("Promo code")}</h6>
                    </div>
                    <div className="col-6">
                      <p className="mb-0 text-end">0%</p>
                    </div>
                  </div>
                )}
                {this.props?.home === true || this.props.Saddress?.[0]?.service_fee ? (
                  <div className="row mb-2">
                    <div className="col-6">
                      <h6 className="fw-bold mb-1">{this.props.Saddress?.[0]?.type === "CollectionPoint" ? this.props.t("Service fee") : this.props.t("Delivery fee")}</h6>
                    </div>
                    <div className="col-6">
                      <p className="mb-0 text-end">{this.props.Saddress && this.props.Saddress?.[0] && this.props.Saddress?.[0]?.service_fee ? this.props.Saddress?.[0]?.service_fee : '4,90'}  €</p>
                    </div>
                  </div>
                ) : null}
                <div className="row mb-2">
                  <div className="col-6">
                    <h6 className="fw-bold mb-1">{this.props.t("Total (tvac)")}</h6>
                  </div>
                  <div className="col-6">
                    <p className="mb-0 text-end">
                      {this?.props?.address !== true
                        ? parseFloat(this.props?.totl)
                          ?.toFixed(2)
                          .replace(".", ",")
                        : parseFloat(this.props?.totl + 4.9)
                          ?.toFixed(2)
                          .replace(".", ",")}
                      €
                    </p>
                  </div>
                </div>
                <button
                  onClick={this.handleEditDelivery}
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                  type="submit"
                  className={`${this.state.loading
                    ? "w-100 btn btn-custom-onboard rounded mt-4 mb-2 ms-auto me-auto d-flex justify-content-center disbaled"
                    : "w-100 fill_btn white fw-bold border-0 py-2 rounded-pill mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                    }`}
                >
                  {this.state.loading
                    ? `${this.props.t("processing")}`
                    : `${this.props.t("Confirm")}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  pickUpDateDeliveryFormatted: state.auth.pickUpDateDeliveryFormatted,
  pickUpDateDelivery: state.auth.pickUpDateDelivery,
  pickUpSlotDelivery: state.auth.pickUpSlotDelivery,
  cartItems: state.cart.cartItems,
  address: state?.auth?.homeAddress,
  Saddress: state?.auth?.selectedAddress,
  time: state?.cart?.time,
  totalAmount: state.cart.totalAmount,
  pickup: state?.cart?.pickup,
  pCode: state?.cart?.promo,
  abc: state?.cart?.home,
  paymentMethod: state?.auth?.paymentMethod,
  paymentCard: state?.auth?.paymentCard,
  desc: state?.auth?.desc,
  currentId: state?.auth?.currentId,
  editDelivery: state?.auth?.edit,
  editOrder: state?.auth?.editOrder,
  pickupSlot: state?.auth?.pickupTime,
  deliverySlot: state?.auth?.deliveryTime,
  authUser: state?.auth?.user,
  home: state?.cart?.home,
  currentOrder: state?.auth?.current,
  userProfile: state?.auth?.profileUser,
});
export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(EditDelivery);