import React, { useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  cartApi,
  incDec,
  setQuantity,
} from "../../../../../store/Actions/cartActions";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import Config from "../../../../Config";
import Cookies from "js-cookie";
import { TiInfoLarge } from "react-icons/ti";

const ItemCard = (props) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  // const cartApi = async (product) => {
  //   try {
  //     let cart = await Axios({
  //       url: `${Config.apiURL}/customer/cart`,
  //       method: "PUT",
  //       headers: {
  //         uid: store?.getState()?.auth?.user?.user_uid,
  //         client: store?.getState()?.auth?.user?.user_client,
  //         "access-token": store?.getState()?.auth?.user?.user_access_token,
  //       },
  //       data: {
  //         cart: {
  //           cart_products_attributes: [product],
  //         },
  //       },
  //     });
  //     const response = await cart.data;
  //     console.log({ response });
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };

  const handleIncDec = (sign) => {
    // props.dispatch(incDec(props?.item, sign));
    props.dispatch(cartApi(props?.item, sign));
    // props.dispatch(incDec(props?.item, sign));
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {/* <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6 my-3"> */}
      <div className="my-3 me-4">
        <div className="box w_157px h_100per p-2 rounded d-flex flex-column justify-content-between">
          <div className={`position-relative h_100per ${isHovered ? 'theme_color_z_index' : ''}`}>
            <div className="text-center">
              <div style={{ width: '100%', position: 'relative', height: 141, backgroundColor: '#3D4586', borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center", }}>
                {props?.item?.description && 
                <TiInfoLarge
                  className="info-icon position-absolute top-0 start-0"
                  size={20}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ cursor: 'pointer' }}
                />}
                {!isHovered &&
                  <img
                    src={props?.src ? props?.src : '/assets/imgs/box_img.png'}
                    style={{ maxWidth: '100%', maxHeight: 101 }}
                    alt=""
                  />
                }
              </div>
            </div>
            {isHovered &&
              <div className="hover-description">
                {props?.description}
              </div>
            }
            {!isHovered &&
              <h3 style={{ textAlign: 'center', fontSize: 16, fontWeight: '800' }}>{props?.title ? `${t(props.title)}` : "No title"}</h3>
            }
            <div className="d-flex flex-row justify-content-between">
              <strong className="m-0 bold theme_color">
                {props?.price
                  ? parseFloat(props?.price)?.toFixed(2)?.replace(".", ",") + "€"
                  : "Free"}
              </strong>
              <div className="d-flex flex-row align-items-center justify-content-center">
                {props?.elem === props?.id ? (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      borderRadius: "0 0 0 0",
                      padding: 0,
                      margin: 0,
                      fontSize: 19
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => handleIncDec("minus")}
                      icon={faMinusCircle}
                      className="theme_color"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      borderRadius: "0 0 0 0",
                      padding: 0,
                      margin: 0,
                      fontSize: 19
                    }}
                  >
                    <FontAwesomeIcon
                      // onClick={() => cartApi({product_id: props?.id, quantity: 1})}
                      icon={faMinusCircle}
                      className="theme_color disabled"
                      aria-hidden="true"
                    />
                  </button>
                )}
                <span className="mx-2 theme_color fw-bold">
                  {props?.quantity === undefined || props?.quantity === null
                    ? null
                    : props?.quantity}
                </span>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    borderRadius: "0 0 0 0",
                    padding: 0,
                    margin: 0,
                    fontSize: 19
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleIncDec("plus")}
                    icon={faPlusCircle}
                    className="theme_color"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state?.cart?.cartItems,
});

export default connect(mapStateToProps)(ItemCard);
