import React, { useState, useEffect } from "react"
import StarRating from "../../Star"
import Axios from "axios"
import Config from "../../../../Config"
import { setIndex, setCurrent } from "../../../../../store/Actions/authActions"
import { connect } from "react-redux"
import "../../../Header/user.css"
import PastOrderModal from "../../../Modals/PastOrder/index"
import { useTranslation } from "react-i18next"
import moment from "moment"
import cookies from "js-cookie"
import FiveStarRating from "../../../Modals/FiveStarRating"

const PastOrder = (props) => {
  const [pastOrders, setPastOrders] = useState([])
  const [rating, setRaiting] = useState("")
  const [orderAgain, setOrderAgain] = useState([])
  const [length, setLength] = useState(null)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [fullRating, setFullRating] = useState(false)

  const { t } = useTranslation()
  const getPastAddress = async () => {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders`,
        method: "GET",
        headers: {
          uid: props?.authUser?.user_uid,
          client: props?.authUser?.user_client,
          "access-token": props?.authUser?.user_access_token,
          // uid: JSON.parse(localStorage.getItem("user_uid")),
          // client: JSON.parse(localStorage.getItem("user_client")),
          // "access-token": JSON.parse(localStorage.getItem("user_access-token")),
        },
      })
      if (!pastOrders?.length) {
        let array = response?.data?.past_orders.filter(
          (item) => item.collection_point_id || item.delivery_address
        )
        // console.log("array filtered", array);
        setPastOrders(array)
        setLength(array.length)
        props?.dispatch(setCurrent(response?.data?.past_orders[0]))

        // console.log("past order => ", pastOrders);
      } else {
        // console.log("past order => ", pastOrders);
      }
    } catch (err) {
      // console.log("past err ", err.response);
    }
  }

  let items = []
  let leftSide = props?.index - 3
  if (leftSide <= 0) leftSide = 1
  let rightSide = props?.index + 3
  if (rightSide > length) rightSide = length
  for (let number = props?.index + 1; number <= rightSide; number++) {
    items.push(
      <div
        style={{ cursor: "pointer" }}
        key={number}
        className={
          number === props?.index + 1 ? "round-effect active " : "round-effect"
        }
        onClick={() => {
          props?.dispatch(setCurrent(pastOrders[number - 1]))
          props?.dispatch(setIndex(number - 1))
        }}
      >
        {number}
      </div>
    )
  }
  const nextPage = () => {
    if (props?.index < length - 1) {
      props?.dispatch(setCurrent(pastOrders[props?.index + 1]))
      props?.dispatch(setIndex(props?.index + 1))
    } else {
      return
    }
  }

  const prevPage = () => {
    if (props?.index >= 1) {
      props?.dispatch(setCurrent(pastOrders[props?.index - 1]))
      props?.dispatch(setIndex(props?.index - 1))
    } else {
      return
    }
  }
  const handleChange = (event) => {
    setRaiting(event.target.value)
  }

  const handleSubmit = async (id) => {
    // debugger
    setLoading(true)
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/reviews`,
        method: "POST",
        data: {
          stars: props.ratingCount,
          review_content: rating,
          order_id: id,
        },
        headers: {
          uid: JSON.parse(localStorage.getItem("user_uid")),
          client: JSON.parse(localStorage.getItem("user_client")),
          "access-token": JSON.parse(localStorage.getItem("user_access-token")),
        },
      })
      const stars_count = response.data.review.stars
      if (stars_count == 5) {
        setFullRating(true)
      }

      if (stars_count !== 5) {
        window.location.reload(false);
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log("review err ", err.response);
    }
  }

  useEffect(() => {
    getPastAddress()
    // console.log("props from past page", orders);
  }, [])


  return (
    <>
      {!pastOrders?.length ? (
        <h6 className="theme_color text-center"> {t("No past orders")}</h6>
      ) : (
        <div className="">
          <div className="past_order_box mx-auto position-relative">
            <div className="past_order_box_header d-flex flex-row justify-content-end p-3">
              <div className="w-50 d-flex flex-column justify-content-between border-light border-end px-3">
                <h5 className="m-0 white fs-6 fw-bold">{t("pick-up")}</h5>
                <div className="d-flex flex-row justify-content-between align-items-center date_color">
                  <p className="mb-0">{moment(props?.current?.pickup_date).format('DD/MM/YYYY')}</p>
                  <p className="mb-0 ms-1">{props?.current?.pickup_slot}</p>
                </div>
              </div>
              <div className="w-50 d-flex flex-column justify-content-between px-3">
                <h5 className="m-0 white fs-6 fw-bold">{t("Delivery")}</h5>
                <div className="d-flex flex-row justify-content-between align-items-center date_color">
                  <p className="mb-0">{moment(props?.current?.delivery_date).format('DD/MM/YYYY')}</p>
                  <p className="mb-0 ms-1">{props?.current?.delivery_slot}</p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column p-4 pt-2 border_lrt">
              <p className="mb-0 theme_color fw-bold small text-center">
                {props?.current?.collection_point_id
                  ? props?.current?.collection_point_address
                  : props?.current?.delivery_address}
              </p>
              <ul className="p-0 mb-0 mt-2">
                <h6 className="theme_color fw-bold">{t("Your items")}</h6>

                {props?.current?.products?.map((item, index) => {
                  return (
                    <li className="d-flex flex-row justify-content-between pb-1 theme_color">
                      <span className="small">
                        { cookies.get("i18next") === "en" 
                            ? item.product_name_en
                            : cookies.get("i18next") === "fn"
                              ? item.product_name
                              : cookies.get("i18next") === "du"
                                ? item.product_name_nl
                                : item.product_name_en
                        }
                       </span>
                      <span className="fw-bold small">
                        {item.quantity <= 1
                          ? item.quantity + " " + t("item")
                          : item.quantity + " " + t("items")}
                      </span>
                    </li>
                  )
                })}
                <div className="border-bottom"></div>
                <li className="d-flex flex-row justify-content-between theme_color ps-2 py-3">
                  <span className="small">{t("Price")}</span>
                  <span className="fw-bold">
                    {parseFloat(props?.current?.subtotal)?.toFixed(2)}€
                  </span>
                </li>
              </ul>
            </div>
            <div className="past_order_box_header d-flex flex-row justify-content-between px-4 py-3 white">
              <p className="mb-0 small">{t("Order ID")} :</p>
              <p className="mb-0 fw-bold small">
                {props?.current?.wosh_order_id}
              </p>
            </div>
            <div className="d-flex flex-column px-4 pb-3 border_lrt">
              {props?.current?.review ? (
                <>
                  {" "}
                  <div className="d-flex flex-column justify-content-between align-item-center mb-2">
                    {/* <h6 className="mb-0 fw-bold theme_color small text-center">
                      {t("Review")}
                    </h6> */}
                    <div className="d-flex theme_color flex-row justify-content-between align-items-center">
                      <p className="m-0 lh_28px fw-bold">{t("Rating")}</p>
                      <StarRating
                        rating={props?.current?.review?.stars}
                        reviewed={true}
                      />
                    </div>
                  </div>
                  <p className="m-0 lh_28px theme_color fw-bold">{t("description")}</p>
                  <p>{props?.current?.review?.review_content}</p>
                </>
              ) : (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <h6 className="m-0 lh_28px fw-bold theme_color">
                      {t("Rate your order")}
                    </h6>
                    <StarRating
                      rating={props?.current?.review?.stars}
                      reviewed={false}
                    />
                  </div>
                  <textarea
                    onChange={handleChange}
                    type="text"
                    className="form-control bg_F4F4F4 py-3 mb-3 border bg-transparent"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={t("Any comment?")}
                  />
                </>
              )}
            </div>
            {!props?.current?.review && (
              <button
                data-bs-target='#shareReview'
                disabled={loading}
                onClick={() => {
                  handleSubmit(props?.current?.id)
                }}
                className="w-100 ms-auto btn fill_btn px-5"
              >
                {loading ? t("Processing") : t("Save")}
              </button>
            )}
            <button
              // onClick={() => setOrderAgain(props?.current?.products)}
              data-bs-toggle="modal"
              data-bs-target="#pastOrder"
              to="/home"
              className={cookies.get("i18next") === "fn" ? "btn fill_btn_again theme_color p-4 btn_position_fn hide_in_responsive" : cookies.get("i18next") === "du" ? "btn fill_btn_again theme_color p-4 btn_position_du hide_in_responsive" : "btn fill_btn_again theme_color p-4 btn_position hide_in_responsive"}
            >
              {t("Order again")}
            </button>
          </div>
          <button
            // onClick={() => setOrderAgain(props?.current?.products)}
            data-bs-toggle="modal"
            data-bs-target="#pastOrder"
            to="/home"
            className="btn fill_btn_again theme_color p-4 mx-auto mt-4 show_in_responsive"
          >
            {t("Order again")}
          </button>
          {pastOrders?.length > 0 ? (
            <PastOrderModal history={props?.history} />
          ) : null}
        </div>
      )}
      {
        fullRating &&
        <FiveStarRating
          toggleModal={() => setFullRating(!fullRating)}
        />
      }
      {pastOrders?.length > 0 ? (
        <>
          <div className="paginate-ctn d-flex flex-row justify-content-center align-items-center mt-4">
            <div
              className="round-effect"
              onClick={prevPage}
              style={{ cursor: "pointer" }}
            >
              {" "}
              &lsaquo;{" "}
            </div>
            {items ? items : null}
            <div
              className="round-effect"
              onClick={nextPage}
              style={{ cursor: "pointer" }}
            >
              {" "}
              &rsaquo;{" "}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    ratingCount: state.auth.rating,
    index: state?.auth?.index,
    current: state?.auth?.current,
    items: state?.cart?.mockItems,
    authUser: state?.auth?.user,
  }
}
export default connect(mapStateToProps)(PastOrder)
